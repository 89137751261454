import { toast } from "react-toastify";
import api from "../../../../services/api";

export default async function apiDriverFreightStatusChange(currentStatus, targetStatus, identifier) {
    const steps = ["DRAFT", "CREATED", "SENT_TO_DRIVER", "REFUSED_BY_DRIVER", "ACCEPTED_BY_DRIVER", "CANCELLED"];

    const currentStep = steps.indexOf(targetStatus) - steps.indexOf(currentStatus);

    try {
        if (!currentStep) {
            return () => {};
        }

        if (targetStatus === "CANCELLED") {
            return await api.post(
                `/adm/driver-freight/${identifier}/change-status/cancel`,
                {},
                {
                    headers: {
                        Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                    },
                }
            );
        }

        if (currentStatus === "REFUSED_BY_DRIVER" && targetStatus === "ACCEPTED_BY_DRIVER") {
            toast.error("Não é possivel avançar esta etapa");
            return Promise.reject();
        }

        if (currentStep <= -1) {
            if (currentStatus === "REFUSED_BY_DRIVER" && targetStatus === "SENT_TO_DRIVER") {
                toast.error("Clique na viagem para selecionar um novo motorista");
                return Promise.reject();
            }

            if (currentStatus === "ACCEPTED_BY_DRIVER" && targetStatus === "REFUSED_BY_DRIVER") {
                return await api.post(
                    `/adm/driver-freight/${identifier}/change-status/refused`,
                    {},
                    {
                        headers: {
                            Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                        },
                    }
                );
            }
            toast.error("Não é possivel voltar etapas");
            return Promise.reject();
        }

        if (currentStep === 1) {
            switch (targetStatus) {
                case steps[1]:
                    return await api.post(
                        `/adm/driver-freight/${identifier}/change-status/create`,
                        {},
                        {
                            headers: {
                                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                            },
                        }
                    );
                case steps[2]:
                    return await api.post(
                        `/adm/driver-freight/${identifier}/change-status/send-to-driver`,
                        {},
                        {
                            headers: {
                                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                            },
                        }
                    );
                case steps[3]:
                    return await api.post(
                        `/adm/driver-freight/${identifier}/change-status/refused`,
                        {},
                        {
                            headers: {
                                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                            },
                        }
                    );
                default:
            }
        } else if (currentStep === 2 && targetStatus === "ACCEPTED_BY_DRIVER") {
            switch (targetStatus) {
                case steps[4]:
                    return await api.post(
                        `/adm/driver-freight/${identifier}/change-status/accepted`,
                        {},
                        {
                            headers: {
                                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                            },
                        }
                    );
                default:
            }
        } else {
            toast.error("Não é possivel pular etapas");
            return Promise.reject();
        }
    } catch (e) {
        toast.error(e);
        return Promise.reject(e);
    }
}
